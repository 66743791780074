import request from '@/util/request'

export function getEventList(params) {
  return request({
    url: '/Event.asmx/GetEventList',
    method: 'GET',
    params,
  })
}

export function getEventInfo(params) {
  return request({
    url: '/Event.asmx/GetEventInfo',
    method: 'GET',
    params,
  })
}
